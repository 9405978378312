
const WIDGET_LIST = [
  {
    value: "CARD",
    label: "Card",
    discretion: "A simple card to display content such as text, images, or UI components.",
    thumbnail: "https://mdn.alipayobjects.com/huamei_7uahnr/afts/img/A*QXO1SKEdIzYAAAAAAAAAAAAADrJ8AQ/original",
  },
  {
    value: "TABLE",
    label: "Table",
    discretion: "A table widget for displaying data in rows and columns.",
    thumbnail: "https://mdn.alipayobjects.com/huamei_7uahnr/afts/img/A*3yz3QqMlShYAAAAAAAAAAAAADrJ8AQ/original",
  },
  {
    value: "IMAGE",
    label: "Image",
    discretion: "A widget to display an image.",
    thumbnail: "https://mdn.alipayobjects.com/huamei_7uahnr/afts/img/A*FbOCS6aFMeUAAAAAAAAAAAAADrJ8AQ/original",
  }
];

const data = [
  {
    "name": "John Doe",
    "reason": "Business Trip",
    "route": "New York to Los Angeles",
    "time": ["morning", "evening"],
    "duration": "5 hours"
  },
  {
    "name": "Jane Smith",
    "reason": "Vacation",
    "route": "San Francisco to Seattle",
    "time": ["night"],
    "duration": "3 hours"
  },
  {
    "name": "Michael Johnson",
    "reason": "Conference",
    "route": "Chicago to Boston",
    "time": ["morning"],
    "duration": "2 hours"
  },
  {
    "name": "Sarah Lee",
    "reason": "Business Trip",
    "route": "Austin to Dallas",
    "time": ["evening"],
    "duration": "1.5 hours"
  },
  {
    "name": "David Brown",
    "reason": "Family Visit",
    "route": "Los Angeles to San Francisco",
    "time": ["night", "morning"],
    "duration": "6 hours"
  }
]
;

export { data, WIDGET_LIST };

