import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Grid, Input, Row } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import videojs from "video.js";

const FusifyVideoSearch = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [searchTerm, setSearchTerm] = useState("");
  const [videoUrls, setVideoUrls] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const defaultVideoURL =
    "https://feedio-ai.s3.us-east-1.amazonaws.com/videos/media-facebot/default/default_playlist.m3u8";

  useEffect(() => {
    const player = videojs(videoRef.current, {
      controls: false,
      autoplay: true,
      muted: false,
      preload: "auto",
      sources: [
        {
          src: defaultVideoURL,
          type: "application/x-mpegURL",
        },
      ],
    });

    playerRef.current = player;

    player.on('ended', () => {
      if (currentVideoIndex < videoUrls.length - 1) {
        setCurrentVideoIndex((prevIndex) => prevIndex + 1);
      } else {
        setVideoUrls([]);
        player.src({ src: defaultVideoURL, type: "application/x-mpegURL" });
        setCurrentVideoIndex(0); // Reset index to start from the first video
      }
    });

    player.ready(() => {
      player.play().catch((error) => {
        console.log("Autoplay failed:", error);
      });
    });
    player.volume(0);
    return () => {
      player.dispose();
    };
  }, []);

  useEffect(() => {
    if (videoUrls.length > 0 && playerRef.current) {
      playerRef.current.src({ src: videoUrls[currentVideoIndex], type: "application/x-mpegURL" });

      playerRef.current.play().catch((error) => {
        console.log("Autoplay failed on video change:", error);
      });
      playerRef.current.volume(1);
    }

    playerRef.current.on('ended', () => {
      if (currentVideoIndex < videoUrls.length - 1) {
        setCurrentVideoIndex((prevIndex) => prevIndex + 1);
      } else {
        console.log("currentVideoIndex", currentVideoIndex);
        setVideoUrls([]);
        playerRef.current.src({ src: defaultVideoURL, type: "application/x-mpegURL" });
        setCurrentVideoIndex(0); // Reset index to start from the first video
      }
    });
    console.log(currentVideoIndex);
  }, [currentVideoIndex, videoUrls]);

  const handleSearch = () => {
    axios
      .post(`https://9yrts99ryd.execute-api.us-east-1.amazonaws.com/dev/`, {
        question: searchTerm,
        action: "get_answer",
      })
      .then((response) => {
        setSearchTerm("");
        const newVideoUrls = response.data.data.answer_video_url || [];
        if (newVideoUrls.length > 0) {
          setVideoUrls(newVideoUrls); // Set the video URLs from API
          setCurrentVideoIndex(0); // Start from the first video
          playerRef.current.volume(1);
        } else {
          console.error("No video URLs returned from API.");
        }
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  };


  return (
    <div style={{ position: "relative", minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: `#F0F8FF`, backgroundSize: "cover", backgroundPosition: "center center" }}>
     
  
      <div className="container" style={{ textAlign: "center", zIndex: 1 }}>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 692.43 355.4"
            style={{
              enableBackground: "new 0 0 692.43 355.4",
            }}
            xmlSpace="preserve"
            width={"15%"}
          >
            <g>
              <g>
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M307.16,112.5h-41.42v27.52h40.02v15.44h-40.02v39.88h-16.15V97.05h57.57V112.5z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M368.65,125.13h15.16v70.2h-15.16v-8.99c-4.59,7.21-11.84,10.81-21.76,10.81 c-8.05,0-14.56-2.57-19.52-7.72c-4.96-5.15-7.44-12.21-7.44-21.2v-43.1h15.16v41.56c0,5.24,1.4,9.27,4.21,12.08 c2.81,2.81,6.65,4.21,11.51,4.21c5.34,0,9.64-1.66,12.92-4.98c3.28-3.32,4.91-8.4,4.91-15.23V125.13z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M414.71,144.41c0,2.31,1.3,4.14,3.89,5.48c2.59,1.34,5.71,2.57,9.34,3.69 c3.63,1.12,7.35,2.27,11.16,3.44s6.99,3.35,9.55,6.52c2.55,3.17,3.83,7.17,3.83,12c0,6.74-2.6,12.03-7.79,15.87 c-5.2,3.84-11.72,5.76-19.59,5.76c-6.93,0-12.87-1.45-17.83-4.35c-4.96-2.9-8.56-6.93-10.81-12.08l13.06-7.58 c2.43,6.74,7.63,10.11,15.58,10.11c7.96,0,11.93-2.62,11.93-7.86c0-2.25-1.29-4.07-3.86-5.48c-2.58-1.4-5.68-2.67-9.31-3.79 c-3.63-1.12-7.35-2.27-11.16-3.44c-3.81-1.17-7-3.28-9.58-6.32c-2.58-3.04-3.86-6.9-3.86-11.58c0-6.46,2.46-11.65,7.37-15.58 c4.91-3.93,11.02-5.9,18.32-5.9c5.8,0,10.97,1.29,15.52,3.86c4.54,2.58,8.02,6.16,10.46,10.74l-12.78,7.16 c-2.43-5.34-6.83-8-13.2-8c-2.9,0-5.33,0.63-7.3,1.87C415.69,140.19,414.71,142.01,414.71,144.41z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M475.93,166.69c1.12,5.33,3.7,9.43,7.72,12.29c4.02,2.86,8.94,4.28,14.74,4.28 c8.05,0,14.09-2.9,18.11-8.71l12.5,7.3c-6.93,10.2-17.18,15.3-30.75,15.3c-11.42,0-20.64-3.49-27.66-10.46 c-7.02-6.97-10.53-15.8-10.53-26.47c0-10.48,3.46-19.26,10.39-26.33c6.93-7.07,15.82-10.6,26.68-10.6 c10.29,0,18.74,3.6,25.34,10.81c6.6,7.21,9.9,15.96,9.9,26.26c0,1.59-0.19,3.7-0.56,6.32H475.93z M475.79,154.34h41.28 c-1.03-5.71-3.39-10.01-7.09-12.92c-3.7-2.9-8.03-4.35-12.99-4.35c-5.62,0-10.3,1.54-14.04,4.63 C479.2,144.79,476.81,149,475.79,154.34z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M580.24,109.83c-11.05-1.03-16.57,3.6-16.57,13.9v1.4h16.57v14.6h-16.57v55.6h-15.16v-55.6h-10.67 v-14.6h10.67v-1.4c0-9.64,2.69-16.96,8.07-21.97c5.38-5.01,13.27-7.18,23.66-6.53V109.83z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M639.21,125.13h16.15l-26.96,72.45c-6.74,18.16-18.16,26.77-34.26,25.83v-14.18 c4.77,0.28,8.61-0.73,11.51-3.02c2.9-2.29,5.29-5.88,7.16-10.74l0.7-1.4l-29.77-68.94h16.57l20.93,50.96L639.21,125.13z"
                />
              </g>
              <g>
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M271.1,237.56h-15.48v10.28h14.95v5.77h-14.95v14.9h-6.03v-36.73h21.51V237.56z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M289.41,269.2c-3.85,0-7.12-1.33-9.81-3.99c-2.69-2.66-4.04-5.93-4.04-9.81 c0-3.88,1.35-7.15,4.04-9.81c2.69-2.66,5.96-3.99,9.81-3.99c3.88,0,7.16,1.33,9.84,3.99c2.68,2.66,4.01,5.93,4.01,9.81 c0,3.88-1.34,7.15-4.01,9.81C296.58,267.88,293.3,269.2,289.41,269.2z M283.59,261.33c1.57,1.57,3.52,2.36,5.82,2.36 s4.25-0.79,5.82-2.36s2.36-3.55,2.36-5.93c0-2.38-0.79-4.36-2.36-5.93s-3.52-2.36-5.82-2.36s-4.25,0.79-5.82,2.36 s-2.36,3.55-2.36,5.93C281.23,257.78,282.02,259.76,283.59,261.33z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M314.71,246.69c1.43-3.25,4.13-4.88,8.08-4.88v6.14c-2.17-0.14-4.06,0.38-5.67,1.55 c-1.61,1.17-2.41,3.1-2.41,5.8v13.22h-5.67v-26.24h5.67V246.69z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M355.26,231.79c3.46,0,6.36,1.15,8.68,3.46c2.33,2.31,3.49,5.18,3.49,8.6 c0,3.39-1.16,6.25-3.49,8.58s-5.22,3.49-8.68,3.49h-7.5v12.59h-6.03v-36.73H355.26z M355.26,250.26c1.78,0,3.25-0.6,4.41-1.81 c1.15-1.21,1.73-2.74,1.73-4.59c0-1.85-0.58-3.38-1.73-4.59c-1.16-1.21-2.62-1.81-4.41-1.81h-7.5v12.8H355.26z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M378.09,246.69c1.43-3.25,4.13-4.88,8.08-4.88v6.14c-2.17-0.14-4.06,0.38-5.67,1.55 c-1.61,1.17-2.41,3.1-2.41,5.8v13.22h-5.67v-26.24h5.67V246.69z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M410.57,242.29h5.67v26.24h-5.67v-3.78c-2.13,2.97-5.19,4.46-9.18,4.46c-3.6,0-6.68-1.34-9.23-4.01 c-2.55-2.68-3.83-5.94-3.83-9.79c0-3.88,1.28-7.15,3.83-9.81c2.55-2.66,5.63-3.99,9.23-3.99c3.99,0,7.05,1.47,9.18,4.41V242.29z M396.35,261.41c1.57,1.59,3.55,2.39,5.93,2.39c2.38,0,4.35-0.79,5.93-2.39s2.36-3.59,2.36-6.01c0-2.41-0.79-4.42-2.36-6.01 s-3.55-2.39-5.93-2.39c-2.38,0-4.36,0.8-5.93,2.39s-2.36,3.59-2.36,6.01C393.99,257.82,394.77,259.82,396.35,261.41z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M444.36,242.29h5.56v25.03c0,3.99-1.31,7.05-3.94,9.18c-2.62,2.13-5.81,3.2-9.55,3.2 c-6.05,0-10.2-2.12-12.44-6.35l4.88-2.83c1.36,2.69,3.92,4.04,7.66,4.04c2.45,0,4.36-0.64,5.75-1.92 c1.38-1.28,2.07-3.05,2.07-5.33v-3.2c-2.13,3.04-5.18,4.56-9.13,4.56c-3.71,0-6.84-1.32-9.39-3.96c-2.55-2.64-3.83-5.83-3.83-9.58 c0-3.74,1.28-6.93,3.83-9.58c2.55-2.64,5.68-3.96,9.39-3.96c3.95,0,7,1.52,9.13,4.56V242.29z M430.06,261.02 c1.59,1.57,3.58,2.36,5.96,2.36s4.36-0.79,5.96-2.36c1.59-1.57,2.39-3.53,2.39-5.88c0-2.34-0.8-4.3-2.39-5.88 c-1.59-1.57-3.58-2.36-5.96-2.36s-4.36,0.79-5.96,2.36s-2.39,3.53-2.39,5.88C427.67,257.49,428.47,259.44,430.06,261.02z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M485.81,241.6c2.97,0,5.37,0.96,7.19,2.89c1.82,1.92,2.73,4.51,2.73,7.77v16.27h-5.67v-15.9 c0-1.82-0.46-3.23-1.36-4.22c-0.91-1-2.15-1.5-3.72-1.5c-1.75,0-3.14,0.58-4.17,1.73c-1.03,1.15-1.55,2.89-1.55,5.19v14.69h-5.67 v-15.9c0-1.82-0.43-3.23-1.29-4.22c-0.86-1-2.07-1.5-3.65-1.5c-1.71,0-3.11,0.59-4.2,1.76c-1.08,1.17-1.63,2.9-1.63,5.17v14.69 h-5.67v-26.24h5.67v3.15c1.68-2.55,4.18-3.83,7.5-3.83c3.36,0,5.84,1.38,7.45,4.14C479.53,242.99,482.21,241.6,485.81,241.6z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M523.22,242.29h5.67v26.24h-5.67v-3.78c-2.13,2.97-5.19,4.46-9.18,4.46c-3.6,0-6.68-1.34-9.23-4.01 c-2.55-2.68-3.83-5.94-3.83-9.79c0-3.88,1.28-7.15,3.83-9.81c2.55-2.66,5.63-3.99,9.23-3.99c3.99,0,7.05,1.47,9.18,4.41V242.29z M509,261.41c1.57,1.59,3.55,2.39,5.93,2.39c2.38,0,4.35-0.79,5.93-2.39s2.36-3.59,2.36-6.01c0-2.41-0.79-4.42-2.36-6.01 s-3.55-2.39-5.93-2.39c-2.38,0-4.36,0.8-5.93,2.39s-2.36,3.59-2.36,6.01C506.64,257.82,507.43,259.82,509,261.41z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M550.77,247.74h-6.51v12.59c0,1.08,0.24,1.86,0.73,2.33c0.49,0.47,1.21,0.73,2.18,0.79 c0.96,0.05,2.16,0.04,3.59-0.03v5.09c-4.34,0.52-7.45,0.15-9.34-1.13c-1.89-1.28-2.83-3.63-2.83-7.06v-12.59h-4.83v-5.46h4.83 v-5.67l5.67-1.68v7.35h6.51V247.74z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M561.92,237.28c-0.72,0.72-1.57,1.08-2.54,1.08s-1.83-0.36-2.54-1.08 c-0.72-0.72-1.08-1.57-1.08-2.54c0-0.98,0.35-1.83,1.05-2.54c0.7-0.72,1.56-1.08,2.57-1.08s1.87,0.36,2.57,1.08 c0.7,0.72,1.05,1.57,1.05,2.54C562.99,235.71,562.63,236.56,561.92,237.28z M556.54,268.52v-26.24h5.67v26.24H556.54z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M581.78,269.2c-3.95,0-7.25-1.33-9.89-3.99c-2.64-2.66-3.96-5.93-3.96-9.81 c0-3.92,1.32-7.2,3.96-9.84c2.64-2.64,5.94-3.96,9.89-3.96c2.55,0,4.88,0.61,6.98,1.84c2.1,1.23,3.67,2.87,4.72,4.93l-4.88,2.83 c-0.6-1.26-1.5-2.25-2.7-2.96s-2.6-1.08-4.17-1.08c-2.31,0-4.24,0.79-5.8,2.36s-2.33,3.53-2.33,5.88c0,2.34,0.78,4.3,2.33,5.88 s3.49,2.36,5.8,2.36c1.54,0,2.93-0.37,4.17-1.1c1.24-0.73,2.18-1.71,2.81-2.94l4.88,2.78c-1.12,2.1-2.73,3.76-4.83,4.98 S584.33,269.2,581.78,269.2z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M636.5,268.52l-2.59-7.4h-15.62l-2.57,7.4h-6.56l13.33-36.73h7.24l13.28,36.73H636.5z M620.29,255.46h11.64l-5.8-16.58L620.29,255.46z"
                />
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M647.57,231.79h6.03v36.73h-6.03V231.79z"
                />
              </g>
            </g>
            <g>
              <path
                style={{
                  fill: "#0021F3",
                }}
                d="M120.15,190.28c0,0-37.69-22.88-54.72-32.8c-0.46-0.27-0.89-0.71-1.24-1.2 c-0.77-5.39-5.22-9.58-10.72-9.95c-0.18-0.02-0.36-0.02-0.54-0.03c-0.09,0-0.17-0.01-0.26-0.01c-0.03,0-0.06,0-0.08,0 c-0.11,0-0.22,0.01-0.33,0.02C46,146.52,41,151.63,41,157.93c0,0.07,0.01,0.14,0.01,0.21c0,0.27,0.03,0.54,0.04,0.81 c0.01,0.08,0.01,0.16,0.02,0.24c0.16,2.05,0.82,4.05,2.03,5.68c0.75,1,1.68,1.83,2.72,2.49c1.92,1.39,4.28,2.23,6.83,2.23 c1.55,0,3.03-0.31,4.39-0.86c0.21,0.06,0.4,0.13,0.57,0.23c16.01,9.17,31.98,18.42,48,27.58c1.47,0.84,1.93,1.77,1.92,3.39 c-0.07,11.74-0.08,23.48,0,35.22c0.01,0.93,0.52,2.25,1.24,2.7c3.46,2.19,7.06,4.15,11.31,6.59c0-1.75,0-2.83,0-3.91 C120.05,225.52,120.15,190.28,120.15,190.28z"
              />
              <path
                style={{
                  fill: "#0021F3",
                }}
                d="M194.51,121.06c-6.08,0-11.06,4.65-11.6,10.59c-0.47,0.72-1.07,1.36-1.72,1.75 c-15.74,9.19-31.53,18.3-47.37,27.31c-0.87,0.5-2.53,0.44-3.42-0.06c-10.27-5.81-22.01-13.2-32.25-19.06 c-0.74-0.42-8.45,5.21-12.41,7.52c0.94,0.66,1.44,1.06,1.99,1.38c13.66,7.86,44.24,26.06,44.24,26.06s38-22.38,55.33-32.29 c0.92-0.52,2.19-0.51,3.33-0.55c1.22,0.43,2.52,0.68,3.88,0.68c6.44,0,11.66-5.22,11.66-11.66 C206.17,126.28,200.95,121.06,194.51,121.06z"
              />
              <path
                style={{
                  fill: "#0021F3",
                }}
                d="M172.95,111.59c-13.51-7.82-27.01-15.65-40.57-23.39c-0.8-0.45-2.28-0.52-3.05-0.08 c-7.74,4.35-15.4,8.86-23.09,13.3c-12.9,7.46-25.82,14.89-38.72,22.35c-3.95,2.28-7.89,4.59-12.31,7.17 c4.19,2.43,7.86,4.6,11.59,6.66c0.47,0.26,1.38,0.13,1.89-0.16c9.35-5.36,18.7-10.73,27.98-16.22c1.9-1.13,3.33-1.02,5.18,0.11 c5.34,3.27,10.76,6.43,16.25,9.44c0.82,0.45,1.55,0.91,2.21,1.44c0.38,6.1,5.43,10.93,11.62,10.93c6.44,0,11.66-5.22,11.66-11.66 c0-6.44-5.22-11.66-11.66-11.66c-1.92,0-3.73,0.47-5.33,1.3c-2.9-1.48-5.76-3.06-8.6-4.67c-2.08-1.18-4.07-2.49-6.25-3.83 c5.99-3.47,11.63-6.71,17.22-10.02c1.23-0.73,2.24-0.84,3.51-0.1c8.61,5.06,33.06,18.99,33.06,18.99s9.49-6.03,11.67-7.36 C175.52,113.12,174.25,112.34,172.95,111.59z"
              />
              <path
                style={{
                  fill: "#0021F3",
                }}
                d="M217.31,152.94c0-0.84,0-1.69,0-3.08c-3.69,2.07-12.02,6.98-12.02,6.98s-0.37,23.42-0.5,34.04 c-0.01,1.04-0.74,2.5-1.59,3.02c-6.3,3.82-12.56,7.74-18.93,11.44c-0.26,0.15-0.51,0.28-0.76,0.39c-1.82-1.17-3.97-1.85-6.29-1.85 c-6.44,0-11.66,5.22-11.66,11.66c0,6.44,5.22,11.66,11.66,11.66c5.91,0,10.78-4.4,11.54-10.11c0.21-0.03,0.4-0.07,0.52-0.14 c4-2.41,8.05-4.74,12.09-7.07c1.03-0.6,2.11-1.12,3.63-1.93c0,1.43,0,2.21,0,2.99c-0.01,5.27-0.22,10.55,0.06,15.81 c0.13,2.55-0.95,3.73-2.89,4.83c-10.11,5.75-20.2,11.54-30.26,17.37c-0.52,0.3-1.11,1.04-1.12,1.58 c-0.09,4.36-0.05,8.73-0.05,13.52c3.69-2.1,7.04-3.99,10.37-5.92c11.09-6.44,22.12-12.97,33.29-19.27 c2.16-1.22,3.04-2.41,3.03-4.93C217.32,206.93,217.33,179.93,217.31,152.94z"
              />
              <path
                style={{
                  fill: "#0021F3",
                }}
                d="M186.96,165.98c-0.61,0.03-46.19,24.3-46.19,24.3s-0.39,47.8-0.49,68c0,0.38-0.08,0.78-0.19,1.18 c-3.27,2.06-5.45,5.7-5.45,9.86c0,6.44,5.22,11.66,11.66,11.66c6.44,0,11.66-5.22,11.66-11.66c0-3.88-1.9-7.31-4.82-9.43 c-0.11-0.37-0.19-0.74-0.2-1.09c-0.15-5.99-0.08-11.98-0.09-17.97c-0.02-12.14-0.07-26.75,0-38.89c0.01-1.02,0.51-2.47,1.27-2.97 c3.24-2.12,24.03-12.72,30.99-16.82c0.74-0.44,1.75-1.27,1.77-1.95C187.04,175.92,186.96,170.38,186.96,165.98z"
              />
              <path
                style={{
                  fill: "#0021F3",
                }}
                d="M120.14,268.93c0.07-1.85-0.62-2.87-2.17-3.75c-9.27-5.29-18.47-10.72-27.77-15.96 c-1.39-0.78-1.68-1.62-1.67-3c0.03-7.19,0.05-14.37-0.02-21.56c-0.01-0.54,0.03-1.04,0.11-1.52c3.16-2.09,5.24-5.66,5.24-9.73 c0-5.53-3.85-10.15-9.02-11.35c-0.09-0.02-0.18-0.05-0.27-0.07c-0.04-0.01-0.07-0.01-0.11-0.02c-0.74-0.15-1.49-0.22-2.25-0.22 c0,0-0.01,0-0.01,0c-0.01,0-0.01,0-0.02,0c-0.35,0-0.71,0.02-1.06,0.05c-0.07,0.01-0.15,0.01-0.22,0.02 c-0.24,0.03-0.49,0.07-0.73,0.11c-0.17,0.03-0.34,0.06-0.51,0.1c-0.08,0.02-0.16,0.04-0.23,0.06c-5.1,1.25-8.89,5.83-8.89,11.31 c0,3.96,1.98,7.46,5,9.56c0.34,1.48,0.31,3,0.33,4.61c0.03,3.59,0.01,7.19-0.01,10.78c0,0.67-0.09,1.34-0.15,2.06 c-0.44-0.08-0.7-0.06-0.89-0.17c-5.48-3.15-10.92-6.36-16.44-9.44c-1.3-0.72-1.47-1.64-1.47-2.92c0.02-10.94,0.12-36.6,0.12-36.6 s-9.48-5.85-12.53-7.66c-0.15,0.51-0.23,0.65-0.23,0.8c-0.02,17.09-0.05,34.18,0.03,51.27c0,0.84,0.89,1.98,1.68,2.45 c6.72,4.01,13.51,7.91,20.29,11.82c15.61,9.01,31.22,18.03,46.83,27.03c2.15,1.24,4.33,2.41,6.98,3.88 C120.1,276.53,119.99,272.73,120.14,268.93z"
              />
            </g>
          </svg>
        </div>

        <Row gutter={[16, 24]} justify="center" align="middle" style={{ width: "100%" }}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "120%",
                height: "36rem",
                borderRadius: "20px",
                overflow: "hidden",
                backgroundColor: "#000",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "20px",
                }}
              />

              <video
                ref={videoRef}
                src={defaultVideoURL}
                autoPlay
                loop
                muted
                controls
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              >
            Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>

        {/* Search Input */}
        <Row gutter={[16, 24]} justify="center" align="middle" className="pt-20" style={{ width: "100%" }}>
          <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={6}>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
              placeholder="Search ..."
              size="large"
              style={{
                width: "120%",
                maxWidth: "600px",
                borderRadius: "50px",
                boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                padding: "4px 20px",
                fontSize: "16px",
                marginRight: "-12px",
                marginBottom: "20px",
              }}
              suffix={
                <Button
                  type="primary"
                  size="large"
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                  style={{
                    borderRadius: "50px",
                    padding: "4px 20px",
                    fontSize: "16px",
                    marginRight: "-12px",
                  }}
                >
                  {screens.xs ? "" : "Go"}
                </Button>
              }
            />
          </Col>
        </Row>
      </div>
      <source src />
    </div>


  );
};

export default FusifyVideoSearch;
